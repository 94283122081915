import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Select, { components } from "react-select";
import { useRecoilValue } from "recoil";
import styles from "../styles/footer.module.scss";
import Cookies from "js-cookie";
import {
  selectDesktopStyles,
  selectFooterMobileStyles,
} from "../styles/footer-select.js";
import towns from "../utils/towns";
import bocadoLogo from "../images/stella_logo3.png";
import stellaArtoisLogo from "../svg/stellaWhiteLogo.svg";
import instagramLogo from "../svg/bocado-footer-instagram.svg";
import youtubeLogo from "../svg/bocado-footer-youtube.svg";
import facebookLogo from "../svg/bocado-footer-facebook.svg";
import twitterLogo from "../svg/bocado-footer-twitter.svg";
import arrow from "../svg/bocado-footer-arrow.svg";
import star from "../svg/bocado-star.svg";
import TyC from "../documents/TyC - Stella.pdf";
import PdP from "../documents/Política de Privacidad - Stella.pdf";
import { showFooterAtom } from "../recoil/atoms.js";

const Footer = ({ link, isMobile, aboutUs, setShowModal }) => {
  const formRef = useRef(null);

  const showFooter = useRecoilValue(showFooterAtom);

  const [submited, setSubmited] = useState(false);
  const [form, setForm] = useState({ terms: "off" });
  const [townsInSelectedProvince, setTownsInSelectedProvince] = useState([]);
  const [enabledButton, setEnabledButton] = useState(false);

  const provinces = [
    { value: "Buenos Aires", label: "Buenos Aires" },
    { value: "Catamarca", label: "Catamarca" },
    { value: "Chaco", label: "Chaco" },
    { value: "Chubut", label: "Chubut" },
    {
      value: "Ciudad Autónoma de Buenos Aires",
      label: "Ciudad Autónoma de Buenos Aires",
    },
    { value: "Córdoba", label: "Córdoba" },
    { value: "Corrientes", label: "Corrientes" },
    { value: "Entre Ríos", label: "Entre Ríos" },
    { value: "Formosa", label: "Formosa" },
    { value: "Jujuy", label: "Jujuy" },
    { value: "La Pampa", label: "La Pampa" },
    { value: "La Plata", label: "La Plata" },
    { value: "La Rioja", label: "La Rioja" },
    { value: "Mendoza", label: "Mendoza" },
    { value: "Misiones", label: "Misiones" },
    { value: "Neuquén", label: "Neuquén" },
    { value: "Río Negro", label: "Río Negro" },
    { value: "Salta", label: "Salta" },
    { value: "San Juan", label: "San Juan" },
    { value: "San Luis", label: "San Luis" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Santa Fe", label: "Santa Fe" },
    { value: "Santiago del Estero", label: "Santiago del Estero" },
    { value: "Tierra del Fuego", label: "Tierra del Fuego" },
    { value: "Tucumán", label: "Tucumán" },
  ];

  const benefits = [
    {
      value: "Preventas exclusivas de merchandising",
      label: "Preventas exclusivas de merchandising",
    },
    {
      value: "Descuentos en cerveza y productos",
      label: "Descuentos en cerveza y productos",
    },
    { value: "Invitaciones a eventos", label: "Invitaciones a eventos" },
    {
      value: "Promociones en restaurantes",
      label: "Promociones en restaurantes",
    },
    {
      value: "Acceso a contenidos únicos",
      label: "Acceso a contenidos únicos",
    },
  ];

  const situations = [
    {
      value: "Cocinando / Almorzando o cenando solo o acompañado",
      label: "Cocinando / Almorzando o cenando solo o acompañado",
    },
    {
      value:
        "Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol",
      label:
        "Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol",
    },
    {
      value: "Con amigxs / compañeros socializando en casa o en un bar",
      label: "Con amigxs / compañeros socializando en casa o en un bar",
    },
    {
      value: "En una fiesta, preboliche, etc.",
      label: "En una fiesta, preboliche, etc.",
    },
    {
      value: "Trasladándome a algún lugar, mientras trabajo o hago mandados",
      label: "Trasladándome a algún lugar, mientras trabajo o hago mandados",
    },
    { value: "No tomo cerveza", label: "No tomo cerveza" },
  ];

  const handleFocus = ({ target }) => {
    let { name } = target;
    if (name === "full_name") {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 1`,
        event_label: `Nombre y Apellido`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
    if (name === "email") {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 2`,
        event_label: `Email`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }

    if (name === "day") {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 7`,
        event_label: `Fecha de nacimiento`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
    if (name === "terms") {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 8`,
        event_label: `TYC`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
  };

  useEffect(() => {
    allowSubmission();
  }, [form]);

  useEffect(() => {
    if (form.province) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 3`,
        event_label: `Provincia`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
    if (form.town) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 4`,
        event_label: `Localidad`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
    if (form.answer) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 5`,
        event_label: `Pregunta 1`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
    if (form.habits) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso 6`,
        event_label: `Pregunta 2`,
        interaction: true,
        component_name: "boton_registrarme",
      });
    }
  }, [form]);

  const onChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const onSelectChange = (item, key) => {
    if (key === "province") {
      const formattedTowns = towns[item.value].map((town) => {
        return {
          value: town,
          label: town,
        };
      });
      setTownsInSelectedProvince(formattedTowns);
    }
    setForm({
      ...form,
      [key]: item.value,
    });
  };

  const onMultiSelectChange = (item, key) => {
    setForm({
      ...form,
      [key]: item.map((item) => item.value).join("; "),
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const td = Cookies.get("_td");
    if (!submited && form.terms === "on") {
      setSubmited(true);
      const {
        full_name,
        email,
        province,
        town,
        answer,
        year,
        month,
        day,
        habits,
      } = form;
      let data = {
        full_name,
        email,
        province,
        town,
        answer,
        birthday: `${year}-${month}-${day}`,
        habits,
        td,
      };

      // window.dataLayer.push({
      //   event: "trackEvent",
      //   eventCategory: "Form",
      //   eventAction: "Click",
      //   eventLabel: "Participar",
      // })

      const api = "https://wastellaartoisveranobe.azurewebsites.net/api/bocado";
      // const api = "http://localhost:8080/api/bocado";

      fetch(`${api}/newsletter`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        // if (typeof window !== "undefined") {
        //   if (window.fbq != null) {
        //     window.fbq("track", "registro")
        //   }
        // }
        if (response.ok) {
          window.dotq = window.dotq || [];
          window.dotq.push({
            projectId: "10000",
            properties: {
              pixelId: "10143685",
              qstrings: {
                et: "custom",
                ea: "bocadoconversion",
              },
            },
          });

          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Success",
            event_label: "",
            interaction: true,
            component_name: "boton_registrarme",
            userId: td ?? "",
          });
          setSubmited(false);
          setShowModal(true);
          setForm({ terms: "off" });
          formRef.current.reset();
        } else {
          setSubmited(false);
          response.text().then((text) => {
            console.error("Error guardando los datos. \n", text);
          });

          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Error",
            event_label: "Error",
            interaction: true,
            component_name: "boton_registrarme",
          });
        }
      });
    }
  };

  const allowSubmission = () => {
    if (
      form.full_name &&
      form.email &&
      form.province &&
      form.town &&
      form.answer &&
      form.year &&
      form.month &&
      form.day &&
      form.terms === "on"
    ) {
      setEnabledButton(true);
    } else {
      setEnabledButton(false);
    }
  };

  const FooterLogos = () => (
    <div className={styles.footerLogosContainer}>
      {isMobile ? (
        aboutUs ? (
          <AboutUsFooterAnchorLink />
        ) : (
          <FooterAnchorLink />
        )
      ) : null}
      <img src={bocadoLogo} alt="Bocado Club" className={styles.footerLogo} />
      <img
        src={stellaArtoisLogo}
        alt="Stella Artois"
        className={styles.smallFooterLogo}
      />
    </div>
  );

  const FooterAnchorLink = () => (
    <AnchorLink to={link} className={styles.footerAnchorLink}>
      <img src={arrow} alt="Back to Top" className={styles.footerArrow} />
    </AnchorLink>
  );

  const AboutUsFooterAnchorLink = () => (
    <a href={link} className={styles.footerAnchorLink}>
      <img src={arrow} alt="Back to Top" className={styles.footerArrow} />
    </a>
  );

  const FooterSocialMedia = () => (
    <div className={styles.footerSocialMediaContainer}>
      <a
        target="_blank"
        href="https://www.instagram.com/stellaartois.ar/"
        rel="noreferrer"
        onClick={() => {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Social",
            event_action: "Click",
            event_label: "https://www.instagram.com/stellaartois.ar/",
            interaction: true,
            component_name: "boton_instagram",
            element_text: "Instagram",
          });
        }}
      >
        <img
          src={instagramLogo}
          alt="Instagram"
          className={styles.footerSocialMediaLogo}
        />
      </a>
      <a
        href="https://www.youtube.com/user/StellaArtoisAR"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Social",
            event_action: "Click",
            event_label: "https://www.youtube.com/user/StellaArtoisAR",
            interaction: true,
            component_name: "boton_youtube",
            element_text: "Youtube",
          });
        }}
      >
        <img
          src={youtubeLogo}
          alt="YouTube"
          className={styles.footerSocialMediaLogo}
        />
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/StellaArtoisArgentina"
        rel="noreferrer"
        onClick={() => {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Social",
            event_action: "Click",
            event_label: "https://www.facebook.com/StellaArtoisArgentina",
            interaction: true,
            component_name: "boton_facebook",
            element_text: "Facebook",
          });
        }}
      >
        <img
          src={facebookLogo}
          alt="Facebook"
          className={styles.footerSocialMediaLogo}
        />
      </a>
      <a
        target="_blank"
        href="https://twitter.com/stellaartois_ar"
        rel="noreferrer"
        onClick={() => {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Social",
            event_action: "Click",
            event_label: "https://twitter.com/stellaartois_ar",
            interaction: true,
            component_name: "boton_twitter",
            element_text: "Twitter",
          });
        }}
      >
        <img
          src={twitterLogo}
          alt="Twitter"
          className={styles.footerSocialMediaLogo}
        />
      </a>
    </div>
  );

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className={styles.footerFormCheckbox}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <div
      className={`${
        aboutUs ? styles.aboutUsFooterContainer : styles.footerContainer
      }
      ${
        aboutUs &&
        !isMobile &&
        !showFooter &&
        styles.aboutUsFooterContainerDisabled
      } ${isMobile && "section"}`}
    >
      <div
        className={`${
          isMobile && aboutUs
            ? styles.aboutUsMobileFooterUpperContainer
            : styles.footerUpperContainer
        }`}
      >
        <div className={styles.footerContent}>
          {!isMobile && (
            <div className={styles.footerLogosAndSocialMediaContainer}>
              <FooterLogos />
              <FooterSocialMedia />
            </div>
          )}
          {isMobile && aboutUs ? null : (
            <form
              ref={formRef}
              onSubmit={onSubmit}
              className={styles.footerForm}
            >
              <img
                className={styles.footerFormStar}
                src={star}
                alt="Stella Artois Star"
              />
              <div className={styles.footerFormTitle}>
                Registrate para disfrutar de los beneficios
              </div>
              <div className={styles.footerFormInputRow}>
                <input
                  className={styles.footerFormInput}
                  placeholder="Nombre y apellido"
                  type="text"
                  required
                  name={"full_name"}
                  onChange={onChange}
                  onFocus={handleFocus}
                />
              </div>
              <div className={styles.footerFormInputRow}>
                <input
                  className={styles.footerFormInput}
                  placeholder="Email"
                  type="text"
                  required
                  name={"email"}
                  onChange={onChange}
                  onFocus={handleFocus}
                />
              </div>
              <div className={styles.footerFormInputRow}>
                <Select
                  id={"selectProvince"}
                  onChange={(item) => onSelectChange(item, "province")}
                  value={
                    form.province
                      ? {
                          value: form.province,
                          label: form.province,
                        }
                      : null
                  }
                  options={provinces}
                  styles={
                    isMobile ? selectFooterMobileStyles : selectDesktopStyles
                  }
                  placeholder="Provincia"
                />
              </div>
              <div className={styles.footerFormInputRow}>
                <Select
                  id={"selectTown"}
                  onChange={(item) => {
                    onSelectChange(item, "town");
                  }}
                  value={
                    form.town
                      ? {
                          value: form.town,
                          label: form.town,
                        }
                      : null
                  }
                  options={townsInSelectedProvince}
                  styles={
                    isMobile ? selectFooterMobileStyles : selectDesktopStyles
                  }
                  placeholder="Localidad"
                  isDisabled={!townsInSelectedProvince.length}
                />
              </div>
              <div className={styles.footerFormInputRow}>
                <Select
                  onChange={(item) => onMultiSelectChange(item, "answer")}
                  value={
                    form.answer
                      ? form.answer.split("; ").map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })
                      : null
                  }
                  options={benefits}
                  styles={
                    isMobile ? selectFooterMobileStyles : selectDesktopStyles
                  }
                  placeholder="¿Qué beneficios te interesan más?"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  controlShouldRenderValue={false}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  components={{
                    Option,
                  }}
                />
              </div>
              <div className={styles.footerFormInputRow}>
                <Select
                  onChange={(item) => onSelectChange(item, "habits")}
                  value={
                    form.habits
                      ? form.habits.split("; ").map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })
                      : null
                  }
                  options={situations}
                  styles={
                    isMobile ? selectFooterMobileStyles : selectDesktopStyles
                  }
                  placeholder="¿En qué ocasiones solés consumir cerveza?"
                />
              </div>
              <div className={styles.footerFormBirthdayTitle}>
                Fecha de Nacimiento
              </div>
              <div className={styles.footerFormInputRow}>
                <input
                  className={styles.footerFormNarrowInput}
                  placeholder="DD"
                  type="text"
                  required
                  name={"day"}
                  onChange={onChange}
                  onFocus={handleFocus}
                />
                <input
                  className={styles.footerFormNarrowInput}
                  placeholder="MM"
                  type="text"
                  required
                  name={"month"}
                  onChange={onChange}
                  onFocus={handleFocus}
                />
                <input
                  className={styles.footerFormNarrowInput}
                  placeholder="AAAA"
                  type="text"
                  required
                  name={"year"}
                  onChange={onChange}
                  onFocus={handleFocus}
                />
              </div>

              <div className={styles.footerFormRadioRow}>
                <input
                  name={"terms"}
                  onChange={onChange}
                  onFocus={handleFocus}
                  required
                  className={styles.footerFormRadio}
                  type="radio"
                />
                <div className={styles.footerFormRadioText}>
                  He leído y acepto{" "}
                  <a
                    href={TyC}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.footerFormRadioLink}
                  >
                    terminos y condiciones
                  </a>
                  ,{" "}
                  <a
                    href={PdP}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.footerFormRadioLink}
                  >
                    políticas de privacidad
                  </a>{" "}
                  y activaciones de marketing.
                </div>
              </div>
              <button
                className={
                  enabledButton
                    ? styles.footerFormButton
                    : styles.footerFormButtonDisabled
                }
              >
                
                Registrarme
              </button>
            </form>
          )}
          {isMobile && <FooterLogos />}
          <div className={styles.footerLinksContainer}>
            <Link to="/faq" title="FAQ" className={styles.footerLink}>
              FAQs
            </Link>
            <a
              className={styles.footerLink}
              href="https://www.craftsociety.com.ar/pages/arrepentimiento"
              target="_blank"
              rel="noreferrer"
            >
              <div className={styles.footerLink}>Botón de Arrepentimiento</div>
            </a>
            <a
              className={styles.footerLink}
              href={TyC}
              target="_blank"
              rel="noreferrer"
            >
              Terminos y Condiciones
            </a>
            <a
              className={styles.footerLink}
              href={PdP}
              target="_blank"
              rel="noreferrer"
            >
              Políticas de Privacidad
            </a>
            <a
              className={styles.footerLink}
              href="https://www.cerveceriaymalteriaquilmes.com/"
              target="_blank"
              rel="noreferrer"
            >
              Información Oficial de la Compañía
            </a>
            <div className={styles.footerLink}>Consumo Responsable</div>
          </div>
          {isMobile && <FooterSocialMedia />}
          {!isMobile ? (
            aboutUs ? (
              <AboutUsFooterAnchorLink />
            ) : (
              <FooterAnchorLink />
            )
          ) : null}
        </div>
      </div>
      <div className={styles.footerWarning}>
        Beber con moderación, prohibida su venta a menores de 18 años. No
        comparta el contenido con menores. Stella Artois ®
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Div100vh from "react-div-100vh";
import {
    selectModalMobileStyles,
    selectDesktopStyles,
} from "../styles/footer-select.js";
import towns from "../utils/towns";
import star from "../svg/bocado-star.svg";
import close from "../svg/modal-x.svg";
import TyC from "../documents/TyC - Stella.pdf";
import PdP from "../documents/Política de Privacidad - Stella.pdf";
import styles from "../styles/bocado-modal.module.scss";
import Cookies from "js-cookie";

const BocadoModal = ({
    type,
    isMobile,
    setShowModal,
    title,
    subtitle,
    onlySuccess,
    bases,
    provinces,
    question,
    answer,
    pageTitle,
    scriptTitle,
    scriptInterest,
}) => {
    const [submited, setSubmited] = useState(false);
    const [success, setSuccess] = useState(onlySuccess ?? false);
    const [form, setForm] = useState({ terms: "off" });
    const [townsInSelectedProvince, setTownsInSelectedProvince] = useState([]);
    const [enabledButton, setEnabledButton] = useState(false);

    const defaultProvinces = [
        { value: "Buenos Aires", label: "Buenos Aires" },
        { value: "Catamarca", label: "Catamarca" },
        { value: "Chaco", label: "Chaco" },
        { value: "Chubut", label: "Chubut" },
        {
            value: "Ciudad Autónoma de Buenos Aires",
            label: "Ciudad Autónoma de Buenos Aires",
        },
        { value: "Córdoba", label: "Córdoba" },
        { value: "Corrientes", label: "Corrientes" },
        { value: "Entre Ríos", label: "Entre Ríos" },
        { value: "Formosa", label: "Formosa" },
        { value: "Jujuy", label: "Jujuy" },
        { value: "La Pampa", label: "La Pampa" },
        { value: "La Plata", label: "La Plata" },
        { value: "La Rioja", label: "La Rioja" },
        { value: "Mendoza", label: "Mendoza" },
        { value: "Misiones", label: "Misiones" },
        { value: "Neuquén", label: "Neuquén" },
        { value: "Río Negro", label: "Río Negro" },
        { value: "Salta", label: "Salta" },
        { value: "San Juan", label: "San Juan" },
        { value: "San Luis", label: "San Luis" },
        { value: "Santa Cruz", label: "Santa Cruz" },
        { value: "Santa Fe", label: "Santa Fe" },
        { value: "Santiago del Estero", label: "Santiago del Estero" },
        { value: "Tierra del Fuego", label: "Tierra del Fuego" },
        { value: "Tucumán", label: "Tucumán" },
    ];

    const displayedProvinces =
        provinces && provinces.length
            ? provinces.map((province) => {
                  return { value: province, label: province };
              })
            : defaultProvinces;

    const getAnswers = () => {
        if (answer) {
            let answers = [...answer];
            answers.shift();
            return answers.map((item) => {
                return { value: item, label: item };
            });
        } else return [];
    };

    const benefits = [
        { value: "Preventas exclusivas", label: "Preventas exclusivas" },
        {
            value: "Acceso a merchandising único",
            label: "Acceso a merchandising único",
        },
        { value: "Descuentos en producto", label: "Descuentos en producto" },
        { value: "Invitación a eventos", label: "Invitación a eventos" },
        { value: "Promos en restaurantes", label: "Promos en restaurantes" },
    ];

    const situations = [
        {
            value: "Cocinando / Almorzando o cenando solo o acompañado",
            label: "Cocinando / Almorzando o cenando solo o acompañado",
        },
        {
            value:
                "Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol",
            label:
                "Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol",
        },
        {
            value: "Con amigxs / compañeros socializando en casa o en un bar",
            label: "Con amigxs / compañeros socializando en casa o en un bar",
        },
        {
            value: "En una fiesta, preboliche, etc.",
            label: "En una fiesta, preboliche, etc.",
        },
        {
            value:
                "Trasladándome a algún lugar, mientras trabajo o hago mandados",
            label:
                "Trasladándome a algún lugar, mientras trabajo o hago mandados",
        },
        { value: "No tomo cerveza", label: "No tomo cerveza" },
    ];

    const [step, setStep] = useState("");

    useEffect(() => {
        const td = Cookies.get("_td");
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Experiencias",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: success ? td : "",
            url: `${window.location.href}`,
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: step ? `step_${step}` : "",
        });
    }, [step, success]);

    useEffect(() => {
        allowSubmission();
    }, [form]);

    const onChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleProvince = (province) => {
        if (
            province === "Buenos Aires - Mar del Plata" ||
            province === "Buenos Aires - Chapadmalal" ||
            province === "Buenos Aires - Pinamar" ||
            province === "La Plata"
        )
            return "Buenos Aires";
        if (province === "Buenos Aires - CABA")
            return "Ciudad Autónoma de Buenos Aires";

        if (province === "Paraná") return "Entre Ríos";
        if (province === "Posadas") return "Misiones";
        if (province === "Rawson") return "Chubut";
        if (province === "Resistencia") return "Chaco";
        if (province === "Río Gallegos") return "Santa Cruz";
        if (province === "Rosario") return "Santa Fe";
        if (province === "San Carlos de Bariloche") return "Río Negro";
        if (province === "San Fernando del Valle de Catamarca")
            return "Catamarca";
        if (province === "San Martin de los Andes") return "Neuquén";
        if (province === "San Miguel de Tucumán") return "Tucumán";
        if (province === "San Salvador de Jujuy") return "Jujuy";
        if (province === "Santa Rosa") return "La Pampa";
        if (province === "Ushuaia") return "Tierra del Fuego";
        if (province === "Viedma") return "Río Negro";
        if (province === "Villa La Angostura") return "Neuquén";
        else return province;
    };

    const onSelectChange = (item, key) => {
        if (key === "province") {
            const formattedTowns = towns[item.value].map((town) => {
                return {
                    value: town,
                    label: town,
                };
            });
            setForm({ ...form, ["town"]: null });
            setTownsInSelectedProvince(
                formattedTowns.sort((a, b) => {
                    const n = a.value.localeCompare(b.value);
                    return n == 0 && a.value !== n.value
                        ? b.value.localeCompare(a)
                        : n;
                })
            );
        }
        setForm({
            ...form,
            [key]: item.value,
        });
    };

    const onMultiSelectChange = (item, key) => {
        setForm({
            ...form,
            [key]: item.map((item) => item.value).join("; "),
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const td = Cookies.get("_td");
        if (!submited && form.terms === "on") {
            setSubmited(true);
            const {
                full_name,
                email,
                province,
                town,
                answer,
                year,
                month,
                day,
                habits,
            } = form;
            let data;
            if (type === "experience")
                data = {
                    full_name,
                    email,
                    province: handleProvince(province),
                    town,
                    birthday: `${year}-${month}-${day}`,
                    experience: title,
                    answer,
                    habits,
                    campaign: pageTitle,
                    td,
                    scriptTitle,
                    scriptInterest,
                };
            if (type === "newsletter")
                data = {
                    full_name,
                    email,
                    province,
                    town,
                    answer,
                    birthday: `${year}-${month}-${day}`,
                    habits,
                };

            const api =
                "https://wastellaartoisveranobe.azurewebsites.net/api/bocado";
            // const api = "http://localhost:8080/api/bocado";

            fetch(`${api}/${type}`, {
                method: "POST",
                body: JSON.stringify(data),
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.ok) {
                    window.dotq = window.dotq || [];
                    window.dotq.push({
                        projectId: "10000",
                        properties: {
                            pixelId: "10143685",
                            qstrings: {
                                et: "custom",
                                ea: "bocadoconversion",
                            },
                        },
                        url: `${window.location.href}`,
                    });
                    window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Form",
                        event_action: "Success",
                        event_label: "",
                        interaction: true,
                        component_name: "boton_registrarme",
                        userId: td ?? "",
                    });
                    setSubmited(false);
                    setSuccess(true);
                } else {
                    setSubmited(false);
                    response.text().then((text) => {
                        console.error("Error guardando los datos. \n", text);
                    });
                    window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Form",
                        event_action: "Error",
                        event_label: "Error",
                        interaction: true,
                        component_name: "boton_registrarme",
                    });
                }
            });
        }
    };

    const allowSubmission = () => {
        if (type === "experience")
            if (
                form.full_name &&
                form.email &&
                form.province &&
                form.town &&
                (form.answer || question === "false") &&
                form.year &&
                form.month &&
                form.day &&
                form.terms === "on"
            ) {
                setEnabledButton(true);
            } else {
                setEnabledButton(false);
            }

        if (type === "newsletter")
            if (
                form.full_name &&
                form.email &&
                form.province &&
                form.town &&
                form.answer &&
                form.habits &&
                form.year &&
                form.month &&
                form.day &&
                form.terms === "on"
            ) {
                setEnabledButton(true);
            } else {
                setEnabledButton(false);
            }
    };

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                        className={styles.bocadoModalCheckbox}
                    />
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    return (
        <div
            className={styles.bocadoModalBackground}
            onClick={() => setShowModal(false)}
        >
            <div
                className={
                    success
                        ? styles.bocadoSuccessModalContainer
                        : styles.bocadoModalContainer
                }
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <img
                    className={styles.bocadoModalCloseIcon}
                    src={close}
                    alt="Close"
                    onClick={() => setShowModal(false)}
                />
                <form
                    id={"formExperiencias"}
                    onSubmit={onSubmit}
                    className={styles.bocadoModalForm}
                >
                    {isMobile || success ? (
                        <img
                            className={styles.bocadoModalFormStar}
                            src={star}
                            alt="Stella Artois Star"
                        />
                    ) : null}
                    <div className={styles.bocadoModalFormTitle}>{title}</div>
                    {success ? (
                        <div className={styles.bocadoSuccessModalBox}>
                            <div className={styles.bocadoSuccessModalText}>
                                {type === "newsletter"
                                    ? "Te registraste con éxito"
                                    : "Gracias por participar"}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={styles.bocadoModalFormSubitle}>
                                {subtitle}
                            </div>
                            <div className={styles.bocadoModalFormInputRow}>
                                <input
                                    className={styles.bocadoModalFormInput}
                                    placeholder="Nombre y apellido"
                                    type="text"
                                    required
                                    name={"full_name"}
                                    onChange={onChange}
                                    onFocus={() => {
                                        setStep("1");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 1`,
                                            event_label: `Nombre y Apellido`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.bocadoModalFormInputRow}>
                                <input
                                    className={styles.bocadoModalFormInput}
                                    placeholder="Email"
                                    type="text"
                                    required
                                    name={"email"}
                                    onChange={onChange}
                                    onFocus={() => {
                                        setStep("2");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 2`,
                                            event_label: `Email`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.bocadoModalFormInputRow}>
                                <Select
                                    onChange={(item) =>
                                        onSelectChange(item, "province")
                                    }
                                    onFocus={() => {
                                        setStep("3");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 3`,
                                            event_label: `Provincia`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                    isSearchable={false}
                                    value={
                                        form.province
                                            ? {
                                                  value: form.province,
                                                  label: form.province,
                                              }
                                            : null
                                    }
                                    options={displayedProvinces}
                                    styles={
                                        isMobile
                                            ? selectModalMobileStyles
                                            : selectDesktopStyles
                                    }
                                    placeholder="Provincia"
                                />
                            </div>
                            <div className={styles.bocadoModalFormInputRow}>
                                <Select
                                    onChange={(item) =>
                                        onSelectChange(item, "town")
                                    }
                                    onFocus={() => {
                                        setStep("4");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 4`,
                                            event_label: `Localidad`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                    isSearchable={true}
                                    value={
                                        form.town
                                            ? {
                                                  value: form.town,
                                                  label: form.town,
                                              }
                                            : null
                                    }
                                    options={townsInSelectedProvince}
                                    styles={
                                        isMobile
                                            ? selectModalMobileStyles
                                            : selectDesktopStyles
                                    }
                                    placeholder="Localidad"
                                    isDisabled={!townsInSelectedProvince.length}
                                />
                            </div>
                            {type === "newsletter" && (
                                <div className={styles.bocadoModalFormInputRow}>
                                    <Select
                                        onChange={(item) =>
                                            onMultiSelectChange(item, "answer")
                                        }
                                        onFocus={() => {
                                            setStep("5");
                                            window.dataLayer.push({
                                                event: "GAEvent",
                                                event_category: "Form",
                                                event_action: `Paso 5`,
                                                event_label: `Pregunta 1`,
                                                interaction: true,
                                                component_name:
                                                    "boton_registrarme",
                                            });
                                        }}
                                        value={
                                            form.answer
                                                ? form.answer
                                                      .split("; ")
                                                      .map((item) => {
                                                          return {
                                                              value: item,
                                                              label: item,
                                                          };
                                                      })
                                                : null
                                        }
                                        options={benefits}
                                        styles={
                                            isMobile
                                                ? selectModalMobileStyles
                                                : selectDesktopStyles
                                        }
                                        placeholder="¿Qué beneficios te interesan más?"
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={false}
                                        isMulti
                                        isClearable={false}
                                        isSearchable={false}
                                        components={{
                                            Option,
                                        }}
                                    />
                                </div>
                            )}
                            {(type === "experience" && question !== "false") && (
                                <div className={styles.bocadoModalFormInputRow}>
                                    <Select
                                        onChange={(item) =>
                                            onSelectChange(item, "answer")
                                        }
                                        isSearchable={false}
                                        onFocus={() => {
                                            setStep("5");
                                            window.dataLayer.push({
                                                event: "GAEvent",
                                                event_category: "Form",
                                                event_action: `Paso 5`,
                                                event_label: `Pregunta 1`,
                                                interaction: true,
                                                component_name:
                                                    "boton_registrarme",
                                            });
                                        }}
                                        value={
                                            form.answer
                                                ? {
                                                      value: form.answer,
                                                      label: form.answer,
                                                  }
                                                : null
                                        }
                                        options={getAnswers(answer)}
                                        styles={
                                            isMobile
                                                ? selectModalMobileStyles
                                                : selectDesktopStyles
                                        }
                                        placeholder={question}
                                    />
                                </div>
                            )}
                            <div className={styles.bocadoModalFormInputRow}>
                                <Select
                                    onChange={(item) =>
                                        onSelectChange(item, "habits")
                                    }
                                    isSearchable={false}
                                    onFocus={() => {
                                        setStep("6");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 6`,
                                            event_label: `Pregunta 2`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                    value={
                                        form.habits
                                            ? form.habits
                                                  .split("; ")
                                                  .map((item) => {
                                                      return {
                                                          value: item,
                                                          label: item,
                                                      };
                                                  })
                                            : null
                                    }
                                    options={situations}
                                    styles={
                                        isMobile
                                            ? selectModalMobileStyles
                                            : selectDesktopStyles
                                    }
                                    placeholder="¿En qué ocasiones solés consumir cerveza?"
                                />
                            </div>
                            <div
                                className={styles.bocadoModalFormBirthdayTitle}
                            >
                                Fecha de Nacimiento
                            </div>
                            <div className={styles.bocadoModalFormInputRow}>
                                <input
                                    className={
                                        styles.bocadoModalFormNarrowInput
                                    }
                                    placeholder="DD"
                                    maxLength="2"
                                    type="text"
                                    required
                                    name={"day"}
                                    onChange={onChange}
                                    onFocus={() => {
                                        setStep("7");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 7`,
                                            event_label: `Fecha de nacimiento`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                />
                                <input
                                    className={
                                        styles.bocadoModalFormNarrowInput
                                    }
                                    placeholder="MM"
                                    maxLength="2"
                                    type="text"
                                    required
                                    name={"month"}
                                    onChange={onChange}
                                    // onFocus={handleFocus}
                                />
                                <input
                                    className={
                                        styles.bocadoModalFormNarrowInput
                                    }
                                    placeholder="AAAA"
                                    maxLength="4"
                                    type="text"
                                    required
                                    name={"year"}
                                    onChange={onChange}
                                    // onFocus={handleFocus}
                                />
                            </div>
                            <div className={styles.bocadoModalFormRadioRow}>
                                <input
                                    name={"terms"}
                                    onChange={onChange}
                                    onFocus={() => {
                                        setStep("8");
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Form",
                                            event_action: `Paso 8`,
                                            event_label: `TYC`,
                                            interaction: true,
                                            component_name: "boton_registrarme",
                                        });
                                    }}
                                    required
                                    className={styles.bocadoModalFormRadio}
                                    type="radio"
                                />
                                <div
                                    className={styles.bocadoModalFormRadioText}
                                >
                                    He leído y acepto{" "}
                                    <a
                                        href={TyC}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={
                                            styles.bocadoModalFormRadioLink
                                        }
                                    >
                                        terminos y condiciones
                                    </a>
                                    ,{" "}
                                    {bases && (
                                        <>
                                            <a
                                                href={bases}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={
                                                    styles.bocadoModalFormRadioLink
                                                }
                                            >
                                                bases y condiciones
                                            </a>
                                            ,{" "}
                                        </>
                                    )}
                                    <a
                                        href={PdP}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={
                                            styles.bocadoModalFormRadioLink
                                        }
                                    >
                                        políticas de privacidad
                                    </a>{" "}
                                    y activaciones de marketing.
                                </div>
                            </div>
                            <button
                                className={`${styles.bocadoModalFormButton} ${
                                    !enabledButton &&
                                    styles.bocadoModalDisabledFormButton
                                }`}
                                onClick={() => {
                                    if (enabledButton && pageTitle === "PARTICIPÁ POR UN VIAJE A NUEVA YORK") {
                                        window.dotq = window.dotq || [];
                                        window.dotq.push(
                                            {
                                                'projectId': '10000',
                                                'properties': {
                                                    'pixelId': '10143685',
                                                    'qstrings': {
                                                        'et': 'custom',
                                                        'ec':'successprogrammatic'
                                                    }
                                                } } );
                                    }
                                }}
                            >
                                {type === "newsletter"
                                    ? "Registrarme"
                                    : "Participar"}
                            </button>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};

export default BocadoModal;

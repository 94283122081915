import React, { useState, useEffect } from "react";
import { useCart } from "../hooks/useCart";
import { useRecoilValue } from "recoil";
import BocadoCartModal from "./bocado-cart-modal";
import styles from "../styles/bocado-cart.module.scss";
import trashCan from "../svg/trash-can.svg";
import x from "../svg/bocado-x.svg";
import minus from "../svg/minus-sign.svg";
import plus from "../svg/plus-sign.svg";
import { cartAtom, cartMoneyTotalSelector } from "../recoil/atoms.js";

// @ts-ignore
import { window } from "browser-monads";

const BocadoCart = ({ isMobile, showCartDrawer, setShowCartDrawer }) => {
    const [delayedHideCart, setDelayedHideCart] = useState(true);

    const cart = useRecoilValue(cartAtom);

    const cartMoneyTotal = useRecoilValue(cartMoneyTotalSelector);

    const [showModal, setShowModal] = useState(false);

    const [
        addToCart,
        reduceItemQuantityByOne,
        removeItemFromCart,
        checkout,
    ] = useCart();

    useEffect(() => {
        showCartDrawer
            ? setDelayedHideCart(false)
            : setTimeout(function () {
                  setDelayedHideCart(true);
              }, 550);
    }, [showCartDrawer]);

    return (
        <>
            {showModal && (
                <BocadoCartModal
                    setShowModal={setShowModal}
                    checkout={checkout}
                />
            )}
            <div
                className={`${
                    showCartDrawer
                        ? styles.cartDarkBackground
                        : styles.cartDarkBackgroundFadeOut
                } ${delayedHideCart && styles.cartDarkBackgroundHidden}`}
                onClick={() => setShowCartDrawer(false)}
            ></div>
            <div
                className={`${styles.cartContainer} ${
                    showCartDrawer && styles.cartVisible
                }
        `}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={styles.cartTitleRow}>
                    <div className={styles.cartTitle}>Carrito</div>
                    <img
                        className={styles.cartCloseIcon}
                        src={x}
                        alt="Close"
                        onClick={() => setShowCartDrawer(false)}
                    />
                </div>
                <div className={styles.cartItemsContainer}>
                    {cart &&
                        cart.map((item, index) => (
                            <>
                                <div className={styles.cartItemContainer}>
                                    <div
                                        className={
                                            styles.cartItemPictureContainer
                                        }
                                    >
                                        <img
                                            className={styles.cartItemPicture}
                                            src={item.img}
                                            alt="Producto"
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.cartItemWithoutPictureContainer
                                        }
                                    >
                                        <div
                                            className={
                                                styles.cartItemNameAndIconContainer
                                            }
                                        >
                                            <div>
                                                <div
                                                    className={
                                                        styles.cartItemTitle
                                                    }
                                                >
                                                    {item.name}
                                                </div>

                                                {item.variants.length === 1 ? (
                                                    <div
                                                        className={
                                                            styles.cartItemBlankVariant
                                                        }
                                                    ></div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.cartItemVariant
                                                        }
                                                    >
                                                        {item.variants[
                                                            item.variantIndex
                                                        ].title === "Single"
                                                            ? "Individual"
                                                            : item.variants[
                                                                  item
                                                                      .variantIndex
                                                              ].title}
                                                    </div>
                                                )}
                                            </div>
                                            <img
                                                className={
                                                    styles.cartItemTrashCanIcon
                                                }
                                                src={trashCan}
                                                alt="Borrar"
                                                onClick={() => {
                                                    window.dataLayer.push({
                                                        event: "GAEvent",
                                                        event_category:
                                                            "Product",
                                                        event_action: `Remove From Cart`,
                                                        event_label:
                                                            "Remover del carrito",
                                                        interaction: true,
                                                        component_name:
                                                            "boton_remover_del_carrito",
                                                        element_text:
                                                            "Remover del carrito",
                                                        product: `${
                                                            item.name
                                                        } ${
                                                            item.variants[
                                                                item
                                                                    .variantIndex
                                                            ].title
                                                        }`,
                                                        sku:
                                                            item.variants[
                                                                item
                                                                    .variantIndex
                                                            ].id,
                                                    });
                                                    return removeItemFromCart(
                                                        item.variants[
                                                            item.variantIndex
                                                        ].id
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.cartItemPriceAndQuantityContainer
                                            }
                                        >
                                            <div
                                                className={styles.cartItemPrice}
                                            >
                                                $
                                                {item.quantity *
                                                    item.variants[
                                                        item.variantIndex
                                                    ].price}
                                            </div>
                                            <div
                                                className={
                                                    styles.cartItemQuantityContainer
                                                }
                                            >
                                                <img
                                                    src={minus}
                                                    alt="Restar"
                                                    className={
                                                        styles.cartItemQuantityIcon
                                                    }
                                                    onClick={() =>
                                                        reduceItemQuantityByOne(
                                                            item.variants[
                                                                item
                                                                    .variantIndex
                                                            ].id
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={
                                                        styles.cartItemQuantity
                                                    }
                                                >
                                                    {item.quantity}
                                                </div>
                                                <img
                                                    src={plus}
                                                    alt="Sumar"
                                                    className={
                                                        styles.cartItemQuantityIcon
                                                    }
                                                    onClick={() => {
                                                        addToCart(
                                                            item,
                                                            item.variantIndex,
                                                            1
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {index !== cart.length - 1 && (
                                    <div className={styles.cartItemHR}></div>
                                )}
                            </>
                        ))}
                </div>
                <div className={styles.cartTotalRow}>
                    <div className={styles.cartTitle}>Subtotal</div>
                    <div className={styles.cartTitle}>${cartMoneyTotal}</div>
                </div>
                <button
                    className={styles.cartSecondaryButton}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Product",
                            event_action: `Content`,
                            event_label: "Continuar comprando",
                            interaction: true,
                            component_name: "boton_continuar_comprando",
                            element_text: "Continuar comprando",
                            product: `${cart[cart.length - 1].name}`,
                            sku: cart[cart.length - 1].product_id,
                        });
                        setShowCartDrawer(false);
                    }}
                >
                    Continuar comprando
                </button>
                <button
                    className={`${styles.cartMainButton} ${
                        !cart?.length && styles.cartDisabledMainButton
                    }`}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Product",
                            event_action: `Content`,
                            event_label: "Checkout",
                            interaction: true,
                            component_name: "boton_checkout",
                            element_text: "Checkout",
                            product: `${cart[cart.length - 1].name}`,
                            sku: cart[cart.length - 1].product_id,
                        });
                        setShowModal(true);
                    }}
                >
                    Checkout
                </button>
            </div>
        </>
    );
};

export default BocadoCart;

export const selectFooterMobileStyles = {
  container: (provided) => ({
    ...provided,
    fontFamily: "Apercu-Bold",
    fontSize: "3.5vw",
    marginTop: "4vw",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "1.5vw",
    border: state.isFocused ? "1px solid #ff0047" : "1px solid #ffffff",
    minHeight: 0,
    width: "84vw",
    outline: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    alignSelf: "flex-start",
    "&:hover": {
      border: "1px solid #ff0047",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginRight: "5vw",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    color: "#2e384d",
    borderRadius: "0.375vw",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "2vw",
    height: "60vw",
    backgroundColor: "#212120",
    border: "1px solid #ff0047",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    height: "60vw",
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#ff0047" : "#ffffff",
    padding: "5vw 4vw",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ff0047",
      color: "#212120",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "5vw 4vw",
  }),
}

export const selectDesktopStyles = {
  container: (provided) => ({
    ...provided,
    fontFamily: "Apercu-Bold",
    fontSize: "0.7vw",
    marginTop: "1vw",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.25vw",
    border: state.isFocused ? "1px solid #ff0047" : "1px solid #ffffff",
    minHeight: 0,
    width: "20vw",
    outline: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    alignSelf: "flex-start",
    "&:hover": {
      border: "1px solid #ff0047",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginRight: "0.75vw",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    color: "#2e384d",
    borderRadius: "0.375vw",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0.5vw",
    maxHeight: "9.5vw",
    backgroundColor: "#212120",
    border: "1px solid #ff0047",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: "9.5vw",
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#ff0047" : "#ffffff",
    padding: "0.5vw 0.6vw",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ff0047",
      color: "#212120",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0.5vw 0.6vw",
  }),
}

export const selectModalMobileStyles = {
  container: (provided) => ({
    ...provided,
    fontFamily: "Apercu-Bold",
    fontSize: "16",
    marginTop: "3vw",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "1.5vw",
    border: state.isFocused ? "1px solid #ff0047" : "1px solid #ffffff",
    minHeight: 0,
    width: "84vw",
    outline: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    alignSelf: "flex-start",
    "&:hover": {
      border: "1px solid #ff0047",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginRight: "5vw",
    zIndex:10,
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    color: "#2e384d",
    borderRadius: "0.375vw",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "2vw",
    height: "57.5vw",
    backgroundColor: "#212120",
    border: "1px solid #ff0047",
    zIndex:1000,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    height: "56.75vw",
    zIndex:1000,
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#ff0047" : "#ffffff",
    padding: "3vw 4vw",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ff0047",
      color: "#212120",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff",
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "3vw 4vw",
  }),
}

import React from "react"
import close from "../svg/modal-x.svg"
import tadaLogo from "../svg/tada_logo.svg"
import styles from "../styles/bocado-cart-modal.module.scss"

const BocadoCartModal = ({ checkout, setShowModal }) => {
  return (
    <div
      className={styles.bocadoCartModalBackground}
      onClick={() => setShowModal(false)}
    >
      <div
        className={styles.bocadoCartModalContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <img
          className={styles.bocadoCartModalCloseIcon}
          src={close}
          alt="Close"
          onClick={() => setShowModal(false)}
        />
        <div className={styles.bocadoCartModalTitle}>Vas a ser redirigido</div>
        <div className={styles.bocadoCartModalTextAndLogoContainer}>
          <div className={styles.bocadoCartModalText}>
            La compra se realizará a través de
          </div>
          <img
            className={styles.bocadoCartModalLogo}
            src={tadaLogo}
            alt="Tadá Logo"
          />
        </div>
        <div className={styles.bocadoCartModalButtonsContainer}>
          <button
            className={styles.bocadoCartModalSecondaryButton}
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </button>

          <button
            className={styles.bocadoCartModalMainButton}
            onClick={checkout}
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  )
}

export default BocadoCartModal

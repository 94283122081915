import { string } from "prop-types"
import React, { useState, useEffect } from "react"
import { useRecoilState } from "recoil"
import { navigate } from "gatsby"
// import { toast } from "react-toastify"

import { cartAtom } from "../recoil/atoms.js"

export const useCart = () => {
  const [cart, setCart] = useRecoilState(cartAtom)

  const setLocalStorageCart = (value) => {
    try {
      window.localStorage.setItem("cart", JSON.stringify(value))
    } catch (err) {
      console.log(err)
    }
  }

  const emptyStorage = () => {
    try {
      window.localStorage.removeItem("cart")
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setLocalStorageCart(cart)
    console.log("carrito modificado", cart)
  }, [cart]) //eslint-disable-line

  const checkIfItemExistsInCart = (variantId) => {
    return cart.find(
      (cartItem) => cartItem.variants[cartItem.variantIndex].id === variantId
    )
  }

  const addToCart = (cartItem, variantIndex, quantity) => {
    let itemExists = checkIfItemExistsInCart(cartItem.variants[variantIndex].id)
    setCart(() => {
      if (itemExists) {
        return cart.map((item) => {
          if (
            cartItem.variants[variantIndex].id ===
            item.variants[item.variantIndex].id
          ) {
            return {
              ...item,
              quantity: itemExists.quantity + quantity,
            }
          }
          return item
        })
      } else return [...cart, { ...cartItem, variantIndex, quantity }]
    })
    // toast.success("Producto agregado al carrito")
  }

  function reduceItemQuantityByOne(variantId) {
    const cartItem = cart.find(
      (item) => item.variants[item.variantIndex].id === variantId
    )
    if (cartItem.quantity === 1) {
      setCart(() =>
        cart.filter((item) => item.variants[item.variantIndex].id !== variantId)
      )
    } else {
      setCart(() =>
        cart.map((item) => {
          return item.variants[item.variantIndex].id === variantId
            ? {
                ...item,
                quantity: item.quantity - 1,
              }
            : item
        })
      )
    }
    // toast.warn("Producto removido")
  }

  function removeItemFromCart(variantId) {
    const nuevoCarrito = cart.filter(
      (item) => item.variants[item.variantIndex].id !== variantId
    )
    setCart(nuevoCarrito)
    // toast.warn("Producto removido")
  }

  function checkout() {
    let url = "https://www.craftsociety.com.ar/cart/add?"

    if (cart.length > 0) {
      cart.forEach((item, i) => {
        let param = i === 0 ? "" : "&"

        param += `id[]=${item.variants[item.variantIndex].id}&quantity=${
          item.quantity
        }`

        url += param
      })
    }

    emptyStorage()
    navigate(url)
  }

  return [addToCart, reduceItemQuantityByOne, removeItemFromCart, checkout]
}
